import { Link, Head } from '@inertiajs/react';
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout';
import React, {useRef, useState, useEffect} from 'react';
import SeriesCard from './Series/Partials/SeriesCard';
import { useDispatch, useSelector } from 'react-redux';
import { truncateRecent, appendRecent } from '@/Store/listSlice';
import PrimaryButton from '@/Components/PrimaryButton';

export default function Welcome({ auth }) {
    let seriesList = "";
    const recent = useSelector(state => state.readingLists.recent);
    const [page, setPage] = useState(1);
    const [noMoreData, setNoMoreData] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log('page ready');
        fetchData(1);
    }, []);

    const fetchData = (pageNumber) => {
        if (!isFetching && !noMoreData) {
            setIsFetching(true);
            axios.get(`/api/series/recent_paged?page=${pageNumber}`)
                .then((response) => {
                    console.log(response);
                    const newData = Object.values(response.data.data);
                    if (response.data.current_page >= response.data.last_page) {
                        setNoMoreData(true);
                    }
    
                    // append only unique data
                    dispatch(appendRecent(newData));
                    setPage(pageNumber + 1);
                    setIsFetching(false);
                })
                .catch(error => {
                    console.log(error);
                    setIsFetching(false);
                    setNoMoreData(true);
                });
        }
    }

    const fetchMore = () => {
        fetchData(page);
    }

    seriesList = recent.length > 0
        ?   recent.map((s, i) => {
                return (
                    <SeriesCard key={s.id} series={s} user={auth.user !== null ? auth.user.id : 0} />
                );
            })
        : <p>You have no series yet.</p>;

    return (
        <AuthenticatedLayout
            header={<h2 className="font-semibold text-xl leading-tight">Welcome</h2>}
        >
            <Head title="Welcome" />

            <div id="core">
                <div className="text">
                    <h2>App Now on Play Store</h2>
                    <p>Find and browse reading lists easily on your Android device. Download the app from the <a href="https://play.google.com/store/apps/details?id=au.id.murraygunn.design.reading_order">Play Store</a>.</p>
                    <p>Register to follow, track and create reading lists.</p>
                    <div className="flex">
                        <img src="/storage/view-list-en.png" className="mobile-screenshot" style={{borderColor: "#1d4ed8"}} />
                        <img src="/storage/inspiration-en.png" className="mobile-screenshot" style={{borderColor: "#b91c1c"}} />
                        <img src="/storage/recent-en.png" className="mobile-screenshot" style={{borderColor: "#047857"}} />
                        <img src="/storage/find-book-en.png" className="mobile-screenshot" style={{borderColor: "#374151"}} />
                        <img src="/storage/my-lists-en.png" className="mobile-screenshot" style={{borderColor: "#b45309"}} />
                    </div>
                </div>
                <div className="text">
                    <p>All book data is provided by openlibrary.org</p>
                    <p>You can search by <Link href={route('series')}>list name</Link> or you can search for all lists containing a specific <Link href={route('book')}>book</Link>.</p>
                    <p>Log in to create your own lists or track your progress through lists made by others.</p>
                    {seriesList.length > 0
                        ? <p>Here are the most recent lists.</p>
                        : <p>We're loading up the latest lists right now.</p>
                    }
                </div>
                <div className="content flex">
                    {seriesList.length && seriesList}
                </div>
                {!noMoreData &&
                    <PrimaryButton className='blue' onClick={fetchMore}>Load More</PrimaryButton>
                }
            </div>
        </AuthenticatedLayout>
    );
}
